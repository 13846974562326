body, html {

    font-family: "Arial", monospace;
    background-color: black;
    background-image: linear-gradient(#E0F2F1, #E3F2FD, #E8F5E9);



    text-align: center;
    color: black;

    -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;


}
div{
  color: black;
  font-family: "Arial", monospace;
  font-style: normal;
  font-variant:normal;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

img{
  user-drag: none;
   user-select: none;
   -moz-user-select: none;
   -webkit-user-drag: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}


.headlanguage{
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: black;
  border-radius: 0px 0px 15px 15px;
}





.socialHeadButton{
  width: 40%;
	text-align: center;
	padding: 5px 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #000000;
	color: #000000;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.socialHeadButton:not(:hover){
	color: #000000;
  background-color: transparent !important;
}
.socialHeadButton:hover {
	background: #90CAF9;
}

.socialHeadButton a{
	color: #000000;
}

.socialHeadButtonWhatsapp{
  width: 40%;
	text-align: center;
	padding: 5px 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #000000;
	color: #000000;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.socialHeadButtonWhatsapp:not(:hover){
	color: #000000;
  background-color: transparent !important;
}
.socialHeadButtonWhatsapp:hover {
  	background: #A5D6A7;
}




.socialmobileiconhead{
  height: 50px;
  width: 50px;
}






.socialslogan{
  text-align: center;
  font-size: 24px;
}

/* dropdown menu languase */
.dropbtn {
  background-color: black;
  color: white;
  font-size: auto;
  border: none;
  cursor: pointer;
  color: white;
  width: 6em;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  border-radius: 0px 0px 15px 15px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: black;

  overflow: hidden;
  z-index: 1;
  right: 0;
  width: 6em;
  border-radius: 0px 0px 15px 15px;
  background-size: cover;


}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  text-decoration: none;
  display: block;
}

.dropdown a {
  color: white;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-size: cover;
  background-color: white;
  color: black;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: black;
  border-radius: 0px 0px 0px 0px;
}
/* dropdown menu languase */







.langbut{
  background-color: black;
  color: white;
  width: 33%;
  border: 1px solid black;
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  border-radius: 0px 0px 15px 15px;
}

.service-name{
  text-align: left;
  font-weight: bold;
}
.services{
    text-align: left;
}

.service-desing{
  text-align: left;
}

.form-input{
  padding: 0.5em;
  width: 100%;
  background-color: black;
  color: white;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 15px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

::selection{
  background: #000000;
}

input {
  outline-color: black;
}
input:focus {
  outline:0 !important;
}

.needtext{
  text-align: left;
  font-size: 24px;
  line-height: 1;
}

.mybutton{
  padding: 0.5em;
  background-color: black;
  color: white;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  border-radius: 15px;
}

button:focus {
  outline:0 !important;
}

.icons{
  width : 50px;
  height : 50px;
  float:left;
  margin-bottom: 0.7em;
}

.company-slogan {
  font-family:  "Courier New Bold", monospace;
  font-size: 3vw;
  text-align: left;
}

.slogan-description{
  font-family:  "Courier New Bold", monospace;
  font-size: 2vw;
  text-align: left;
}


/* .backtop{
  background-image: url('./image/hero4.jpg');
  background-color: #000000;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 4%;
} */
.logopart{
  position:relative;
 display:inline-block;
  text-align: center;
}

.formclass{
  line-height: inherit;
}

.dirsection{
  text-align: left;
  margin-top: 3em;
  margin-bottom: 3em;
  font-size: 18px;
}

.spartnersname{
  margin-bottom: 2.4em;
  margin-left: 1.8em;
  margin-right: 1.8em;
  text-align: left;
  /* -webkit-filter: drop-shadow(2px 2px 2px #000000);
  filter: drop-shadow(0px 0px 50px #000000); */
}

.contacts{
  text-align: left;
  margin-bottom: 5em;
  margin-left: 1.8em;
  margin-right: 1.8em;
}
.contacts-item{
  margin-bottom: 1em;
}

.footer{
  padding-bottom: 0.7em;
  padding-top: 0.7em;
  color: white;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  background-color: black;
  border-radius: 15px 15px 0px 0px;
}



.distance{
  padding-top:20%;
  margin-bottom: 20%;
}

.logoimg{
  height: 250px;
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.error{
  text-align: center;
 font-family: 'Times New Roman';
 font-size: 5.5vw;
 margin-top: 3em;
}

.backerror{
  min-height: 100vh;
  padding: 0;
}

.colright{
  text-align: right;
}


@media only screen and (max-width: 1000px) {
  .company-slogan {
    font-family: "Courier New Bold", monospace;
    font-size: 22px;
  }

  .slogan-description{
    font-family: "Courier New Bold", monospace;
    font-size: 18px;
  }

  div.col-md-4.servicesection{
    width: 50%;
  }


  div.colright{
    width: 100%;
  }
  div.colleft{
    width: 100%;
  }

  .socialmobileiconhead{
    height: 25px;
    width: 25px;
  }

  .colright{
    text-align: center;
  }

  .colleft{
    text-align: center;
  }

  .socialslogan{
    font-size: 20px;
  }


}


@media only screen and (max-width: 700px) {
  div.col-md-4.servicesection{
    width: 100%;
  }

  .socialHeadButton{
    width: 60%;
  }

  .socialHeadButtonWhatsapp{
    width: 60%;
  }

  .socialslogan{
    font-size: 18px;
  }


}

@media only screen and (max-width: 322px) {
  .company-slogan {
    font-family: "Courier New Bold", monospace;
    font-size: 19px;
  }

  .slogan-description{
    font-family: "Courier New Bold", monospace;
    font-size: 20px;
  }

  .logoimg{
    height: 150px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }


    .socialHeadButton{
      width: 100%;
    }

    .socialHeadButtonWhatsapp{
      width: 100%;
    }

    .socialslogan{
      font-size: 17px;
    }

}
